<template>
    <section>
        <InsideHeader :title="pageData.page_heading" :bredcrumb="breadCrumb" />
        <div class="section py-5 gallery-page ">
            <div class="container">                
                <div class="row">
                    <div class="col-xl-12 col-lg-12 col-md-12" >
                        <CoolLightBox 
                            :items="items" 
                            :index="index"
                            @close="index = null"
                            v-if="items.length > 0"
                            :dir="dir"
                        >
                        </CoolLightBox>

                        <div class="images-wrapper-row row" v-if="items.length > 0">
                            <div class="image-wrapper-col col-sm-4 col-md-3 col-lg-3 col-6 mb-3"  v-for="(image, imageIndex) in items" :key="imageIndex">
                                <div
                                    class="image"                                   
                                    @click="index = imageIndex"
                                    :style="{ backgroundImage: 'url(' + image + ')' }"
                                ></div>
                            </div>
                        </div>
                        <NoRecord v-else />    
                    </div>                    
                </div>
            </div>
        </div>
    </section> 
</template>

<script>
import InsideHeader from "@/components/InsideHeader.vue";
import NoRecord from "@/components/NoRecord.vue";
import Translation from '../../../public/translation.json'

import CoolLightBox from 'vue-cool-lightbox'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'

export default {
    components: {
        InsideHeader, NoRecord, CoolLightBox
    },
    data(){
        return {
            id: this.$route.params.id,
            items: [],
            index: null,
            breadcrumb: [],
            dir_path: '',
            defaultLang: localStorage.getItem('_store_lang') || 'en',
            pageData: [],
            galleryData: [],
            filePath: '',
            dir: 'ltr'
        }
    },   
    methods: {
        showImg(index) {
            this.index = index
            this.visible = true
        },
        pageContent(field){
            return Translation.content[this.defaultLang][field]
        },
        getPageData() {
            let slug = 'gallery'
            this.$store.dispatch('getPageData', slug).then(res=>{
               if(res.data.status === true) {
                    this.pageData = res.data.data
                    if(res.data.dir_path) {
                        this.dir_path = res.data.dir_path
                    }
                }
            })
            .catch(err => {
                console.log(err)
                this.$router.push({name: '404'})
            })
        },
        getData(){            
            this.$http.get(`/gallery/${this.id}/photos`).then(res=>{
                if(res.status === 200 && res.data){
                    if(res.data.data.data){
                        this.galleryData = res.data.data.data
                        this.galleryData.map(item=>{
                            this.items.push(res.data.path+ '/large/'+ item.image_name)
                        })
                        console.log(this.items)
                    }
                    if(res.data.path)
                        this.filePath = res.data.path
                }
            })
        }
    },    
    computed: {
        breadCrumb(){
            return this.breadcrumb = [
                {title: this.pageContent('home'), path:'/'},
                {title: this.pageContent('gallery'), path:'#'}
            ]
        }       
    },
    created: function(){
        this.getPageData()
        this.getData()
        this.dir = this.defaultLang === 'ar' ? 'rtl' : 'ltr'
    },
}
</script>


<style scoped>
.images-wrapper .image-wrapper {
    margin-bottom: 20px;
    width: 100%;
    margin-right: 20px;
}
.images-wrapper {
    margin-right: -20px;
}
.images-wrapper .image-wrapper .image {
    cursor: pointer;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    /* padding-top: 100%; */
    width: 200px;
    height: 200px;
}
</style>